import Swiper, {
    Navigation
} from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';

window.addEventListener("DOMContentLoaded", () => {
    init();
});

function init() {
    navigation();
    copyToClipboard();
    slider();
    submitForm();
}

function navigation() {
    var scrollY;
    var scrollpos = window.pageYOffset || document.documentElement.scrollTop;
    var toggleNav = true;
    var whiteNavActive = true;
    const nav = document.querySelector('.c-nav');
    const navWhite = document.querySelector('.c-nav--white');
    const navLogo = document.querySelector('.c-nav__logo');
    const progressBar = document.querySelector('.c-nav__progress-indicator');
    const navLogoSrc = '/assets/images/logo.svg';
    const navLogoWhiteSrc = '/assets/images/logo_white.svg';
    const hambuger = document.querySelector('.c-nav__menu');
    const mobileNav = document.querySelector('.c-nav__content');
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const menu = document.querySelector('.c-nav__menu');
    const activeWhiteNav = nav.classList.contains('c-nav--white');

    hambuger.addEventListener('click', () => {
        if (toggleNav) {
            toggleNav = false;
            openMobileNav();
        } else {
            toggleNav = true;
            closeMobileNav();
        }
    });

    window.addEventListener("load", () => {
        nav.classList.add('c-nav--transition');
        navPosition();
        noTransition();
        calcPageHeight(window);
    });

    window.addEventListener('scroll', () => {
        navPosition();
    });

    window.addEventListener("resize", () => {
        calcPageHeight(window);
        if (window.matchMedia('(min-width: 992px)').matches) {
            if (mobileNav.classList.contains('c-nav__content--active')) closeMobileNav();
            noTransition();
        }
    });

    function navPosition() {
        scrollpos = window.pageYOffset || document.documentElement.scrollTop;
        let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        let scrolled = (scrollpos / height) * 100;
        if (progressBar) progressBar.style.width = scrolled + "%";

        if (scrollpos > 10) {
            nav.classList.add('c-nav--active');
            if (navWhite) whiteNav('hide');
        } else {
            nav.classList.remove('c-nav--active');
            if (navWhite) whiteNav('show');
        }
    }

    function noTransition() {
        let timer = 0;
        if (timer) {
            clearTimeout(timer);
            timer = null;
        } else {
            nav.classList.add('c-nav--no-transition');

            timer = setTimeout(() => {
                nav.classList.remove('c-nav--no-transition');
                timer = null;
            }, 250);
        }
    }

    function calcPageHeight() {
        document.documentElement.style.setProperty(
            "--window-inner-height",
            `${window.innerHeight}px`
        );
    }

    function openMobileNav() {
        scrollY = window.scrollY;
        mobileNav.classList.add('c-nav__content--active');
        body.classList.add('c-nav--disable-scroll');
        html.classList.add('c-nav--disable-scroll');
        menu.classList.add('c-nav__menu--active');
        mobileNav.addEventListener("pointermove", preventDefault);
        whiteNavActive = false
        if (navWhite) whiteNav('hide');
    }

    function closeMobileNav() {
        mobileNav.classList.remove('c-nav__content--active');
        body.classList.remove('c-nav--disable-scroll');
        html.classList.remove('c-nav--disable-scroll');
        menu.classList.remove('c-nav__menu--active');
        mobileNav.removeEventListener("pointermove", preventDefault);
        whiteNavActive = true
        window.scrollTo(0, scrollY);
        if (navWhite) whiteNav('show');
    }

    function whiteNav(state) {
        if (state == 'show' && whiteNavActive) {
            nav.classList.add('c-nav--white');
            navLogo.src = navLogoWhiteSrc;
        } else if (state == 'hide') {
            nav.classList.remove('c-nav--white');
            navLogo.src = navLogoSrc;
        }
    }

    function preventDefault(e) {
        e.preventDefault();
    }
}

function copyToClipboard() {
    const copyLinks = document.querySelectorAll('.c-blog__social-copy');

    copyLinks.forEach(copyLink => {
        copyLink.addEventListener('click', () => {
            const copyText = copyLink.querySelector('.c-blog__social-copy-text');
            navigator.clipboard.writeText(copyText.getAttribute('data-url'));
            copyText.innerHTML = "Gekopieerd";
        });
    });

    copyLinks.forEach(copyLink => {
        copyLink.addEventListener('mouseleave', () => {
            const copyText = copyLink.querySelector('.c-blog__social-copy-text');
            copyText.innerHTML = "Kopieer link";
        });
    });
}

function slider() {
    var swiperBlog = document.querySelectorAll('.swiper--blog');
    var swipers = document.querySelectorAll('.swiper');
    var loop = true;

    if (swiperBlog.length != 0) {
        loop = false;
        swipers = swiperBlog;
    }

    swipers.forEach(e => {
        const swiper = new Swiper(e, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 10,
            loop: loop,
            navigation: {
                nextEl: '.c-slider__trigger--next',
                prevEl: '.c-slider__trigger--prev',
                disabledClass: 'c-slider__trigger--disabled'
            },
            breakpoints: {
                576: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3,
                    loop: false
                },
                1400: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    loop: false
                }
            }
        });
    });
}

window.survey = function () {
    let current_page = 1;
    let records_per_page = 1;
    const btn_next = document.querySelector(".c-survey__actions-button--next");
    const text_next = document.querySelector(".js-pagination__next");
    const btn_prev = document.querySelector(".c-survey__actions-button--prev");
    const listing_table = document.querySelector(".js-pagination");
    const listing = document.querySelector('.c-survey__actions');
    const pageCount = document.querySelector(".js-pagination__count");
    const pages = document.querySelectorAll('.js-pagination__item');
    const bars = document.querySelectorAll('.c-survey__progress-bar');
    const error = document.querySelectorAll('.c-survey--error');
    const pagination = document.querySelectorAll('.js-pagination__item');
    const barsActive = 'c-survey__progress-bar--active';

    if (!listing_table || pages.length == 0) return;

    btn_next.addEventListener('click', () => {
        const forms = pagination[current_page - 1].querySelectorAll('.c-survey__item');
        if (!formsValidation(forms)) {
            error[current_page - 1].style.display = 'block';
            window.scrollTo({
                top: 0
            });
        } else {
            if (current_page == numPages()) {
                var items = $('.c-survey__item');
                var itemsLength = items.length;

                items.each(function () {
                    $.ajax({
                        method: 'POST',
                        url: "/actions/poll/answer/submit",
                        data: $(this).serialize(),
                        success: function () {
                            itemsLength--;
                            if (itemsLength == 0) window.location.href = btn_next.getAttribute('data-results');
                        }
                    });
                });
            } else {
                error[current_page - 1].style = ''
                nextPage();
            }
        }
    });

    btn_prev.addEventListener('click', () => {
        prevPage();
    });

    function prevPage() {
        if (current_page > 1) {
            current_page--;
            changePage(current_page);
        }
    }

    function nextPage() {
        if (current_page < numPages()) {
            current_page++;
            changePage(current_page);
        }
    }

    function formsValidation(forms) {
        for (let i = 0; i < forms.length; i++) {
            if (!radioChecked(forms[i])) return false;
        };

        function radioChecked(form) {
            const radios = form.querySelectorAll('.c-survey__answers-radio');
            for (let i = 0; i < radios.length; i++) {
                if (radios[i].checked) return true;
            }
            return false;
        }
        return true;
    }

    function changePage(page) {
        if (page < 1) page = 1;
        if (page > numPages()) page = numPages();

        window.scrollTo({
            top: 0
        });

        for (let i = 0; i < numPages(); i++) {
            if (i < page) bars[i].classList.add(barsActive);
            else if (bars[i].classList.contains(barsActive)) bars[i].classList.remove(barsActive);
            if (i == (page - 1)) pages[i].style.display = 'block';
            else pages[i].style.display = 'none';
        }

        if (page == 1) {
            btn_prev.style.visibility = 'hidden';
        } else {
            btn_prev.style.visibility = 'visible';
        }

        if (page == numPages()) {
            text_next.innerHTML = 'Bekijk resultaat';
        } else {
            text_next.innerHTML = 'Volgende';
        }

        if (numPages() == 1) {
            btn_next.style.display = 'none';
            btn_prev.style.display = 'none';
            pageCount.style.display = 'none';
        }
    }

    function numPages() {
        return Math.ceil(pages.length / records_per_page);
    }

    window.onload = () => {
        changePage(1);
    };
}

function submitForm() {
    const form = document.querySelector('.c-form');
    const btn = document.querySelector('.js-submit');

    if (!form) return;

    form.addEventListener("submit", function (e) {
        btn.innerHTML = 'Aan het versturen...';
        btn.style.backgroundColor = '#004657';
        btn.disabled = true;
    });
}